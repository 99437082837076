import {BlueButton} from '@/components/Button/BlueButton';
import {routes} from 'ff-common';
import {WarningIcon} from '@chakra-ui/icons';
import {Box, Link, Text, VStack} from '@chakra-ui/react';
import {fromRoute} from '@synako/enhanced-router';
import {useHaligator} from '@synako/haligator';
import {useCallback, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

// eslint-disable-next-line react-refresh/only-export-components
export const authErrorRoute = fromRoute(routes.index, {
  path: 'auth/error'
});

export const AuthErrors : React.FC = () => {
  const {t} = useTranslation('common');
  const [query] = useSearchParams();
  const error = query.get('error');
  if (error === 'unverified_email') {
    return <VerifyEmail />;
  } else if (error === 'no_contact') {
    return <NoContact />;
  } else if (error === 'no_sandbox') {
    return <NoSandboxAccess />;
  }
  return (
    <VStack spacing={8} align="stretch" width="100%">
      <Box borderWidth={1} borderRadius="8px" p={6} textAlign="center">
        <Text fontSize={{base: 'lg', md: 'md'}} fontWeight="600" color="blue.main" mb={2}><WarningIcon mr='20px'/>{t('authentification.error.erreur_rencontree')}</Text>
        <Text>{error}</Text>
      </Box>
    </VStack>
  );
};

const VerifyEmail : React.FC = () => {
  const {t} = useTranslation('common');
  const [query] = useSearchParams();
  const user_id = query.get('user_id');
  const {client} = useHaligator();
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<number | undefined>(undefined);
  const reSendEmailValidation = useCallback(() => {
    setSubmitting(true);
    client.fetcher.fetchOrThrow(
      new URL('/authn/auth0/resend-email-validation', client.rootUrl).toString(),
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_id,
        })
      }
    ).then(resp => {
      setSubmitting(false);
      if (resp.status === 200) {
        setSuccess(true);
      } else {
        setError(resp.status);
      }
    });
  }, [client, user_id]);
  return <VStack spacing={8} align="stretch" width="100%">
    <Box borderWidth={1} borderRadius="8px" p={6} textAlign="center">
      <Text fontSize={{base: 'lg', md: 'md'}} fontWeight="600" color="blue.main" mb={2}><WarningIcon mr='20px'/>{t('authentification.error.valider_email')}</Text>
      <BlueButton 
        isDisabled={submitting}
        onClick={reSendEmailValidation}
        texte={t('authentification.error.re_envoyer')}/>
      
      {success &&
      <Text fontSize={{base: 'md', md: 'sm'}} color="gray.dark" mb={4}>
        {t('authentification.error.email_envoye')}
      </Text>
      }
      {error &&
      <Text fontSize={{base: 'md', md: 'sm'}} color="gray.dark" mb={4}>
        {t('authentification.error.erreur')} ({error}), {t('authentification.error.contacter_commercial')}
      </Text>
      }
    </Box>
  </VStack>;
};

const NoContact : React.FC = () => {
  const {t} = useTranslation('common');
  return (
    <VStack spacing={8} align="stretch" width="100%">
      <Box borderWidth={1} borderRadius="8px" p={6} textAlign="center">
        <Text fontSize={{base: 'lg', md: 'md'}} fontWeight="600" color="blue.main" mb={2}><WarningIcon mr='20px'/>{t('authentification.error.email_correspond_plus')}</Text>
      </Box>
    </VStack>
  );
};

const NoSandboxAccess : React.FC = () => {
  const {t} = useTranslation('common');
  return (
    <VStack spacing={8} align="stretch" width="100%">
      <Box borderWidth={1} borderRadius="8px" p={6} textAlign="center">
        <Text fontSize={{base: 'lg', md: 'md'}} fontWeight="600" color="blue.main" mb={2}><WarningIcon mr='20px'/>
          <Trans t={t} components={{Link: <Link textDecoration="underline" href="https://ffconnect.france-frais.fr"/>}}>{t('authentification.error.no_sandbox')}</Trans>
        </Text>
      </Box>
    </VStack>
  );
};
