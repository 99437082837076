import React from 'react';
import {Box, VStack, Text, Link, Flex} from '@chakra-ui/react';
import {routes} from 'ff-common';
import {fromRoute} from '@synako/enhanced-router';
import {useTranslation} from 'react-i18next';
import {BlueButton} from '@/components/Button/BlueButton';

// eslint-disable-next-line react-refresh/only-export-components
export const connectionRoute = fromRoute(routes.index, {
  path: 'connection'
});

// gestion de la redirection si elle est demandée
// redirect_after_login oit être une URL valide
const params = new URLSearchParams(location.search);
const redirect = params.get('redirect_after_login');
const queryParam = redirect ? `&redirect_after_login=${redirect}` : '';

export const Connection: React.FC = () => {
  const {t} = useTranslation('common');

  return (
    <>
      <Text as="h1" fontSize='3xl' fontWeight='700' textAlign="center" width="100%"
        mb={{base: '3xl', md: '0'}} color="blue.main">
        {t('authentification.commande_en_ligne')}
      </Text>

      <VStack spacing={4} align="stretch" width="100%" px='5'>
        <Box borderRadius="8px" p={6} textAlign="center" boxShadow={`0 0 30px 10px var(--chakra-colors-blue-main_transparency_5)`}>
          <Text fontSize={{base: 'lg', md: 'md'}} fontWeight="700" color="blue.main">{t('authentification.compte_france_frais')}</Text>
          <BlueButton
            onClick={() => {window.location.href = `/authn/auth0/login?prompt=login${queryParam}`;}}
            texte={`${t('authentification.connectez_vous')} →`}/>
        </Box>

        <Box borderRadius="8px" p={6} textAlign="center" boxShadow={`0 0 30px 10px var(--chakra-colors-blue-main_transparency_5)`}>
          <Text fontSize={{base: 'lg', md: 'md'}} fontWeight="700" color="blue.main">{t('authentification.premiere_connexion')}</Text>
          <BlueButton
            onClick={() => {window.location.href = `/authn/auth0/login?prompt=login&screen_hint=signup${queryParam}`;}}
            texte={`${t('authentification.activer_compte')} →`}/>
        </Box>

        <Flex align="center" borderRadius="8px" p={5} backgroundColor='blue.main_transparency_5'>
          <Box boxSize="50px" mr={4} bgImage="url('/ffconnect/connexion/img/caller.png')" bgSize="cover" bgPosition="center" />
          <Box fontSize={{base: 'lg', md: 'md'}}>
            <Text fontWeight="700" color="blue.main" mb={1}>{t('authentification.devenir_client')}</Text>
            <Link href="https://www.francefrais.fr/contact" isExternal color="blue.main" textDecoration="underline">
              {t('authentification.contactez_nous')}
            </Link>
          </Box>
        </Flex>
      </VStack>
    </>
  );
};
