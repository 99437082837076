import {useMe} from '@/haligator-factory';
import {routes} from 'ff-common';
import {Flex, VStack, Image, Box} from '@chakra-ui/react';
import {useHalState} from '@synako/haligator';
import {Outlet} from 'react-router-dom';
import {PartieGaucheConnexion} from './PartieGaucheConnexion';
import {LOGO_FF_COULEUR_URL} from '@/domains/mediatheque/logoFFCouleurUrl';
import {useTranslation} from 'react-i18next';
import {imageOptimisee} from '../mediatheque/optimisation';

export const AuthLayout: React.FC = () => {
  const meHook = useHalState(useMe());
  const {t} = useTranslation('common');

  const params = new URLSearchParams(location.search);
  const redirect = params.get('redirect_after_login');

  if(meHook.data?.type === 'contact_client') {
    // gestion de la redirection si elle est demandée
    // redirect_after_login oit être une URL valide
    window.location.replace(redirect ?? routes.client.accueil.path);
  }

  return !meHook.loading ? (
    <Flex minHeight="100vh" direction={{base: 'column', md: 'row'}} bg='white'>

      {/* Partie gauche - cachée sur mobile */}
      <Box position="fixed" top={0} left={0} maxWidth="800px" width="40%" height="100vh" display={{base: 'none', md: 'block'}}>
        <PartieGaucheConnexion />
      </Box>

      {/* Partie droite */}
      <Box marginLeft={{base: 0, md: '40%'}} width={{base: '100%', md: '60%'}} minHeight="100vh" overflowY="auto">
        <Flex width="100%" height="100%" justify="center" align="center" py={8}>
          <VStack width="100%" maxWidth="700px" minWidth={{base: 'none', md: '500px'}} spacing={8} px={6}>

            {/* Logo */}
            <Image src={imageOptimisee(LOGO_FF_COULEUR_URL, 300)} alt={t('global.alt_logo_france_frais')} marginX='auto' w="300px" mt={{base: '30px', md: '0'}} />

            <Outlet />
          </VStack>
        </Flex>
      </Box>
    </Flex>
  ) : null;
};
